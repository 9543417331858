import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import './RadioGroup.scss';

const RadioGroup = ({ name, options, inline }) => {
  const { values, setFieldValue } = useFormikContext();
  const fieldValue = values[name];

  return (
    <div className="radio-group">
      <div
        className={classnames('wrapper', {
          column: inline,
        })}
      >
        {
          options.map((el) => (
            <div
              key={el.id}
              className={classnames('radio', {
                column: inline,
              })}
              onClick={() => {
                setFieldValue(name, el.id);
              }}
            >
              <div
                className={classnames('control', {
                  active: fieldValue === el.id,
                })}
              >
                <div className="inner" />
              </div>
              <div className="label">
                {el.name}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.node,
  })),
};

RadioGroup.defaultProps = {
  inline: true,
  options: [],
};

export default RadioGroup;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';

import config from 'config';

import OrderSpecs from 'components/OrderSpecs';
import CheckoutHeader from 'components/CheckoutHeader';
import RadioGroup from 'components/RadioGroup';
import getData from 'utils/getData';
import saveData from 'utils/saveData';
import countPanels from 'utils/countPanels';

import './ConfiguratorPageStep4.scss';

const deliveryOptions = [{
  id: 'dhl-courier',
  name: <FormattedMessage id="checkoutDeliveryDhlCourier" />,
}, {
  id: 'dhl-pickup',
  name: <FormattedMessage id="checkoutDeliveryDhlPickup" />,
}, {
  id: 'self-pickup',
  name: <FormattedMessage id="checkoutDeliverySelfPickup" />,
}];

const ConfiguratorPageStep4 = ({ typeId }) => {
  const [selectedPanelType, setSelectedPanelType] = useState(typeId || config.panelTypes[0].id);
  const panels = getData('configuration');
  const amountOfPanels = countPanels(panels);

  return (
    <div className="configurator-page-step-4">
      <div className="two-columns inline">
        <div className="left">
          <OrderSpecs
            selectedPanelType={selectedPanelType}
            setSelectedPanelType={setSelectedPanelType}
            amountOfPanels={amountOfPanels}
          />
        </div>
        <div className="right">
          <CheckoutHeader
            currentStep={1}
          />
          <Formik
            initialValues={{
              delivery: 'dhl-courier',
            }}
            onSubmit={(values) => {
              saveData('checkout-step-4', values);
              navigate('/configurator/step-5');
            }}
          >
            <Form>
              <div className="form-wrapper">
                <div className="delivery-wrapper">
                  <RadioGroup
                    name="delivery"
                    options={deliveryOptions}
                    inline={false}
                  />
                </div>
              </div>
              <div className="button-wrapper">
                <button className="btn" type="submit">
                  <FormattedMessage id="checkoutNext" />
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

ConfiguratorPageStep4.propTypes = {
  typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ConfiguratorPageStep4.defaultProps = {
  typeId: null,
};

export default ConfiguratorPageStep4;
